<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
      @close-dialog="$emit('close-dialog')"
    >
      <template #content>
        <v-row>
          <v-col>
            <ExamModeSelect
              v-model="filters.examMode"
              :newExamModeOption="examModeOption"
              @new-exam-mode="openModeFormDialog"
              class="required"
            />
          </v-col>
          <v-col>
            <SubspecialtySelect
              v-model="filters.examSubspecialty"
              class="required"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ProceduresAutocomplete
              type="exam"
              :examMode="filters.examMode"
              :examSubspecialty="filters.examSubspecialty"
              class="required"
              v-model="selectedProcedure"
            />
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>

    <!-- modal de cadastrar nova modalidade -->
    <v-dialog v-model="examModeFormDialog" max-width="400">
      <ExameModesForm
        :mode="'insert'"
        :modalTitle="'Cadastrar modalidade'"
        @close="examModeFormDialog = false"
        @insert-completed="afterInsertExamMode"
      />
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

import ExameModesForm from "@/views/ExamModes/ExamModesForm";
import ProceduresAutocomplete from "@/components/template/FormElements/Procedures/ProceduresAutocomplete";
import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";
import SubspecialtySelect from "@/components/template/FormElements/Masks/SubspecialtySelect";

export default {
  components: {
    BaseForm,
    ExameModesForm,
    ProceduresAutocomplete,
    ExamModeSelect,
    SubspecialtySelect,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    selectedProcedure: null,
    examModeFormDialog: false,
    successDialog: false,
    actionAfterOperation: null,
    form: {},
    examModeOption: {},
    filters: {
      examMode: null,
      examSubspecialty: null,
      type: "exam",
    },
  }),
  computed: {},
  methods: {
    openModeFormDialog() {
      this.examModeFormDialog = true;
    },
    afterInsertExamMode(data) {
      this.examModeOption = { value: data.id, text: data.name };

      this.form.ExamModeId = data.id;

      this.examModeFormDialog = false;
    },
    validate() {
      const errors = [];

      if (!this.selectedProcedure) errors.push("O campo EXAME é obrigatório");

      return errors;
    },

    save(actionAfterOperation) {
      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataToSend();

        const partnershipId = this.$route.params.partnershipId;

        // console.log(this.$route.params);

        await this.$axios.post(
          `/partnerships/${partnershipId}/exams`,
          dataToSend
        );

        this.clearForm();
        this.clearFilters();

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    prepareDataToSend() {
      const form = {};

      console.log(this.selectedProcedure);

      form.ExamReferenceId = this.selectedProcedure.ExamReference.id;

      return form;
    },
    async update() {},
    closeSuccessDialog() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
      else if (this.actionAfterOperation === "stay") {
        this.successDialog = false;
        this.$emit("insert-completed");
      }
    },

    clearFilters() {
      this.filters = {
        examMode: null,
        examSubspecialty: null,
        type: "exam",
      };
    },

    clearForm() {
      this.selectedProcedure = null;
      this.form = {};
    },
  },
  created() {},
};
</script>

<style></style>
