<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
      @close-dialog="$emit('close-dialog')"
    >
      <template #content>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              label="Informe o novo preço do exame para este parceiro"
              v-model="form.price"
              class="required"
              prepend-icon="mdi-currency-usd"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    BaseForm,
  },
  props: {
    partnershipId: {
      type: String,
      required: true,
    },
    examReferenceId: {
      type: String,
      required: true,
    },
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    successDialog: false,
    actionAfterOperation: null,
    form: {
      price: "0,00",
    },
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.form.price) errors.push("O campo PREÇO é obrigatório");

      return errors;
    },

    save(actionAfterOperation) {
      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {},
    async update() {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataToSend();

        await this.$axios.post(
          `/partnerships/${this.partnershipId}/exams/${this.examReferenceId}/prices`,
          dataToSend
        ); //rota do tipo post pois nunca edita, sempre insere um novo preço

        this.clearForm();

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    prepareDataToSend() {
      let formAux = this.form;

      formAux.price = parseFloat(formAux.price).toFixed(2);

      return formAux;
    },
    closeSuccessDialog() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
      else if (this.actionAfterOperation === "stay") {
        this.successDialog = false;
        this.$emit("updated-price");
      }
    },

    clearForm() {
      this.form = {};
    },
  },
  created() {},
};
</script>

<style></style>
