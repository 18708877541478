<template>
  <v-card>
    <v-card-title>Exames do parceiro: {{ partnershipName }}</v-card-title>

    <v-data-table
      :headers="headers"
      :items="examsPartnership"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="success"
            @click.stop="insertDialog = true"
          >
            <v-icon left>mdi-plus</v-icon> Associar novo exame
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          title="Remover associação"
          @click="deleteExamPartnership(item.ExamReferenceId)"
          small
          icon
        >
          <v-icon>mdi-link-variant-remove</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          title="Atualizar preço"
          @click="openPriceModal(item.ExamReferenceId)"
          small
          icon
        >
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog max-width="600" v-model="insertDialog">
      <ExamPartnershipForm
        dialog
        title="Associar novo exame para este parceiro"
        mod="insert"
        @close-dialog="insertDialog = false"
        @insert-completed="afterInsert"
      />
    </v-dialog>

    <v-dialog max-width="600" v-model="procedurePriceDialog">
      <ExamPartnershipPriceForm
        dialog
        title="Atribuir novo preço do exame para este parceiro"
        mod="update"
        :partnershipId="partnershipId"
        :examReferenceId="examReferenceIdToSetPrice"
        @close-dialog="procedurePriceDialog = false"
        @updated-price="afterUpdatePrice"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import ExamPartnershipForm from "@/components/ExamPartnerships/ExamPartnershipForm.vue";
import ExamPartnershipPriceForm from "@/components/ExamPartnerships/ExamPartnershipPriceForm.vue";
import errorHandler from "@/helpers/error_handler";

export default {
  components: { ExamPartnershipForm, ExamPartnershipPriceForm },
  computed: {
    partnershipId() {
      return this.$route.params.partnershipId;
    },
    partnershipName() {
      return this.partnershipData
        ? this.partnershipData.Person.companyName
        : "";
    },
  },
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Exame", value: "name" },
      { text: "Preço (R$)", value: "formattedPrice" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    examsPartnership: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
    procedureCpBranchId: null,
    partnershipData: null,
    examReferenceIdToSetPrice: null,
    procedurePriceToUpdate: null,
    insertDialog: false,
    procedurePriceDialog: false,
  }),
  methods: {
    async loadExamPartnership() {
      try {
        this.loading = true;

        let url = `/partnerships/${this.partnershipId}/exams`;

        const response = await this.$axios.get(url);

        this.setExamPartnership(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setExamPartnership(data) {
      const cpData = { ...data };

      // console.log(cpData);

      const { partnership, examPartnerships } = cpData;

      this.partnershipData = partnership;

      this.examsPartnership = examPartnerships.map((e) => {
        e.price =
          e.ExamPartnershipPricings.length > 0
            ? e.ExamPartnershipPricings[0].price
            : null;

        e.formattedPrice = e.price
          ? parseFloat(e.price).toFixed(2).replace(".", ",")
          : "-";

        e.name = e.ExamReference.Procedure.name;

        return e;
      });
    },
    async deleteExamPartnership(examReferenceId) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar a associação com o id n. ${examReferenceId}?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/partnerships/${this.partnershipId}/exams/${examReferenceId}`;

        await this.$axios.delete(url);

        this.deleteExamPartnershipFromTable(examReferenceId);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteExamPartnershipFromTable(examReferenceId) {
      const index = this.examsPartnership.findIndex(
        (p) => p.ExamRefenceId === examReferenceId
      );

      this.examsPartnership.splice(index, 1);
    },
    openPriceModal(examReferenceId) {
      this.examReferenceIdToSetPrice = String(examReferenceId);
      this.procedurePriceDialog = true;
    },
    afterInsert() {
      this.insertDialog = false;
      this.loadExamPartnership();
    },
    afterUpdatePrice() {
      this.procedurePriceDialog = false;
      this.loadExamPartnership();
    },
  },
  created() {
    this.loadExamPartnership();
  },
};
</script>

<style></style>
